import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'
import Notifications from 'notiwind'
import { i18nVue } from 'laravel-vue-i18n'
import PrimeVue from 'primevue/config';
import PrimeVueTailwindStyle from '@/Pages/styles/primevue-tailwind.js';

import { usePassThrough } from "primevue/passthrough";
import Tailwind from "primevue/passthrough/tailwind";


const CustomTailwind = usePassThrough(
    Tailwind,
    PrimeVueTailwindStyle,
    {
        mergeSections: false,
        mergeProps: true
    }
);




// import Vue from 'vue'

// Vue.use(Vuetify) 

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {

        const app = createApp({ render: () => h(App, props) })
            .use(PrimeVue,{ unstyled: true, pt: PrimeVueTailwindStyle })
            .use(plugin)            
            .use(ZiggyVue, Ziggy)
            .use(Notifications)
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                }
            })
        

             // Add Vite asset entry point for images and fonts
        import.meta.glob([
            '../images/**',
            '../fonts/**',
        ]);
            //use in register route

        const currentRoute = window.location.pathname;
        // if (currentRoute === '/r' || currentRoute == '/login' || currentRoute == '/logout' || currentRoute === '/') {
            const captchaKey = props.initialPage.props?.captcha_site_key;
            if (captchaKey) {
                app.use(VueReCaptcha, { siteKey: captchaKey, autoHideBadge: true });
            }
        // }
            
        return app.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
